import { createCrudActions } from '../utils/crudGenerator';
import moment from 'moment';
import { axios } from "../connection/ConnectionHandler";
import { keyBy } from "lodash";
import { SET_BC_USERS_LIST, SET_PRODUCTS_LIST, SHOW_NOTIFICATION } from "../constants";

const formatUsers = (data) => {
  if (!data) {
    return [];
  }
  const formatItem = (item) => ({
    ...item,
    label: `${item.name} ${item.surname}`,
    value: item._id,
    last_login: item.last_login && moment(item.last_login),
    // Añade aquí cualquier otro campo que necesite formateo especial
  });

  if (Array.isArray(data)) {
    return data.map(formatItem);
  }
  return formatItem(data);
};

export const {
  fetch: fetchUsers,
  create: createUser,
  update: updateUser,
  delete: deleteUsers
} = createCrudActions('USER', "Usuario", formatUsers);

export const fetchBcUsers = () => async (dispatch) => {
  try {
    const response = await axios.get('/bcusers');
    const data = response.data.value.map((user) => ({
      label: user.Name,
      value: user.No,
    }));
    const formattedData = keyBy(data, "value");
    dispatch({
      type: SET_BC_USERS_LIST,
      payload: formattedData
    });
    return response.status;
  } catch (err) {
    console.error(err);
    dispatch({
      type: SHOW_NOTIFICATION,
      payload: {
        show: true,
        status: "error",
        message: "Error al obtener los usuarios de BC.",
      },
    });
    return err;
  }
};

export const fetchProducts = () => async (dispatch) => {
  try {
    const response = await axios.get('/products');
    dispatch({
      type: SET_PRODUCTS_LIST,
      payload: keyBy(response.data, "No")
    });
    return response.status;
  } catch (err) {
    console.error(err);
    return err;
  }
}
