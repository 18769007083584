/* BASIC APP CONSTANTS */
export const AUTH = "AUTH";
export const LOGOUT = "LOGOUT";
export const APP_COOKIE_NAME = "APP_COOKIE_NAME";
export const USER_COOKIE_NAME = "USER_COOKIE_NAME";
export const PASS_COOKIE_NAME = "PASS_COOKIE_NAME";
export const USER_ID_COOKIE_NAME = "USER_ID_COOKIE_NAME";
export const SET_CURRENT_ROUTE = "SET_CURRENT_ROUTE";
export const SET_SIDE_MENU_STATE = "SET_SIDE_MENU_STATE";
export const SHOW_NOTIFICATION = "SHOW_NOTIFICATION";
export const CLOSE_NOTIFICATION = "CLOSE_NOTIFICATION";
export const SHOW_ACTION_MENU = "SHOW_ACTION_MENU";
export const HIDE_ACTION_MENU = "HIDE_ACTION_MENU";
export const SHOW_BOTTOM_TOOLBAR = "SHOW_BOTTOM_TOOLBAR";
export const HIDE_BOTTOM_TOOLBAR = "HIDE_BOTTOM_TOOLBAR";
export const SET_USER_LIST = "SET_USER_LIST";
export const EDIT_DISABLED_BUTTONS = "EDIT_DISABLED_BUTTONS";
export const SET_LOGS_LIST = "SET_LOGS_LIST";
export const THEME = "THEME";

/* <---- BASIC APP CONSTANTS ----> */

/* SOCKET CONSTANTS */
export const CONNECTED = "connected";
export const DISCONNECTED = "disconnected";
/* <---- SOCKET CONSTANTS ----> */

/* CONFIGURATION */
export const CONFIGURATION_FORM_TOOGLE_LOADING =
  "CONFIGURATION_FORM_TOOGLE_LOADING";
export const SET_CONFIGURATION = "SET_CONFIGURATION";
/* <---- CONFIGURATION ----> */

/* USER */
export const SET_PROFILE = "SET_PROFILE";
/* <---- USER ----> */

/* USERS */
export const ADD_NEW_USER = "ADD_NEW_USER";
export const UPDATE_USER = "UPDATE_USER";
export const DELETE_USER = "DELETE_USER";
export const OPEN_USER_FORM = "OPEN_USER_FORM";
export const CLOSE_USER_FORM = "CLOSE_USER_FORM";
export const EDIT_SELECTED_USER = "EDIT_SELECTED_USER";
export const USERS_FORM_TOOGLE_LOADING = "USERS_FORM_TOOGLE_LOADING";
export const SET_BC_USERS_LIST = "SET_BC_USERS_LIST";
export const SET_PRODUCTS_LIST = "SET_PRODUCTS_LIST";
/* <---- USERS ----> */

/* FUNCTIONALITY */
export const SET_FUNCTIONALITY_LIST = "SET_FUNCTIONALITY_LIST";
export const ADD_NEW_FUNCTIONALITY = "ADD_NEW_FUNCTIONALITY";
export const UPDATE_FUNCTIONALITY = "UPDATE_FUNCTIONALITY";
export const DELETE_FUNCTIONALITY = "DELETE_FUNCTIONALITY";
export const OPEN_FUNCTIONALITY_FORM = "OPEN_FUNCTIONALITY_FORM";
export const CLOSE_FUNCTIONALITY_FORM = "CLOSE_FUNCTIONALITY_FORM";
export const EDIT_SELECTED_FUNCTIONALITY = "EDIT_SELECTED_FUNCTIONALITY";
export const FUNCTIONALITY_FORM_TOOGLE_LOADING = "FUNCTIONALITY_FORM_TOOGLE_LOADING";
/* <---- FUNCTIONALITY ----> */

/* SERVICE */
export const SET_SERVICE_LIST = "SET_SERVICE_LIST";
export const ADD_NEW_SERVICE = "ADD_NEW_SERVICE";
export const UPDATE_SERVICE = "UPDATE_SERVICE";
export const DELETE_SERVICE = "DELETE_SERVICE";
export const OPEN_SERVICE_FORM = "OPEN_SERVICE_FORM";
export const CLOSE_SERVICE_FORM = "CLOSE_SERVICE_FORM";
export const EDIT_SELECTED_SERVICE = "EDIT_SELECTED_SERVICE";
export const SERVICE_FORM_TOOGLE_LOADING = "SERVICE_FORM_TOOGLE_LOADING";
/* <---- SERVICE ----> */

/* TYPEBUDGET */
export const SET_TYPEBUDGET_LIST = "SET_TYPEBUDGET_LIST";
export const ADD_NEW_TYPEBUDGET = "ADD_NEW_TYPEBUDGET";
export const UPDATE_TYPEBUDGET = "UPDATE_TYPEBUDGET";
export const DELETE_TYPEBUDGET = "DELETE_TYPEBUDGET";
export const OPEN_TYPEBUDGET_FORM = "OPEN_TYPEBUDGET_FORM";
export const CLOSE_TYPEBUDGET_FORM = "CLOSE_TYPEBUDGET_FORM";
export const EDIT_SELECTED_TYPEBUDGET = "EDIT_SELECTED_TYPEBUDGET";
export const TYPEBUDGET_FORM_TOOGLE_LOADING = "TYPEBUDGET_FORM_TOOGLE_LOADING";
/* <---- TYPEBUDGET ----> */

/* BUDGET */
export const SET_BUDGET_LIST = "SET_BUDGET_LIST";
export const ADD_NEW_BUDGET = "ADD_NEW_BUDGET";
export const UPDATE_BUDGET = "UPDATE_BUDGET";
export const DELETE_BUDGET = "DELETE_BUDGET";
export const OPEN_BUDGET_FORM = "OPEN_BUDGET_FORM";
export const CLOSE_BUDGET_FORM = "CLOSE_BUDGET_FORM";
export const EDIT_SELECTED_BUDGET = "EDIT_SELECTED_BUDGET";
export const BUDGET_FORM_TOOGLE_LOADING = "BUDGET_FORM_TOOGLE_LOADING";
/* <---- BUDGET ----> */

/* ROUTES */
export const SET_ALL_ROUTES = "SET_ALL_ROUTES";
export const UPDATE_ROUTE = "UPDATE_ROUTE";
/* <---- ROUTES ----> */

/* ALERT */
export const SET_ALERT_LIST = "SET_ALERT_LIST";
export const ADD_NEW_ALERT = "ADD_NEW_ALERT";
export const UPDATE_ALERT = "UPDATE_ALERT";
export const DELETE_ALERT = "DELETE_ALERT";
export const OPEN_ALERT_FORM = "OPEN_ALERT_FORM";
export const CLOSE_ALERT_FORM = "CLOSE_ALERT_FORM";
export const EDIT_SELECTED_ALERT = "EDIT_SELECTED_ALERT";
export const ALERT_FORM_TOOGLE_LOADING = "ALERT_FORM_TOOGLE_LOADING";
/* <---- ALERT ----> */
